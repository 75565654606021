import piranha from "./piranha.png";
import "./App.css";

import { useEffect, useState } from "react";

function App() {
  return (
    <div className="App bg-black font-mono ">
      <div className="flex flex-col">
        <div className="w-[80%] mx-auto md:w-[50%]">
          <p
            className="relative text-4xl sm:text-5xl p-2 md:p-8 font-bold  rounded-lg"
            style={{ lineHeight: "3.5rem" }}
          >
            Dev talent doesn't like your job listing 🙉
          </p>
          <p
            className="relative text-xl p-2 md:text-4xl md:p-8 pt-8 rounded-lg"
            style={{ lineHeight: "1.25rem" }}
          >
            <span className="">We can help 😌</span>
            <br />
            <br />
          </p>
          <p className="relative text-md md:text-lg text-left rounded-lg pb-2">
            Our <span className="underline">human</span> engineers
          </p>
          <div className="flex flex-col opacity-70   rounded-lg align-left text-sm md:text-lg p-2 pb-8 items-start">
            <p className="text-left">👉 make sure your tech lingo makes sense</p>
            <p className="text-left">👉 highlight industry red flags</p>
            <p className="text-left">👉 address missing expectations</p>
            <p className="text-left">... and more</p>
          </div>

          <p className="relative text-md md:text-lg text-left rounded-lg pb-4">
            How does it work?
          </p>
          <div className="flex flex-col opacity-70 text-left leading-8  rounded-lg align-left text-sm md:text-sm pb-8 items-start">
            <p className="leading-5 pb-2">1️⃣ Send us a link to your listing</p>
            <p className="leading-5 pb-2">
              2️⃣ We'll immediately confirm your request
            </p>
            <p className="leading-5 pb-2">
              3️⃣ We'll send you a document with comments, suggestions, and
              edits!
            </p>
          </div>

          <p className="relative text-md md:text-lg md:pt-8  text-left rounded-lg ">
            <p className="leading-5 pb-2">
              Submit your listing ▶︎{" "}
              <a
                href="https://forms.gle/6gjvugnWHFqKF57KA"
                target="_blank"
                className="font-bold underline"
              >
                here
              </a>{" "}
              ◀︎ for a free review! 🚀
            </p>
            <span></span>
            <span className="opacity-90"></span>
          </p>

          <p className="relative text-md md:text-lg md:pt-8  text-left rounded-lg "></p>
          {/* <p>Then the better applicants will roll in 🚀</p> */}

          <div className="flex">
            <div className="flex flex-grow"></div>
          </div>
        </div>
      </div>
      {/* </header> */}
      {/* <div className="absolute bottom-0 p-4 text-gray-300 ">
        <p>🍻 cheers to better applicants! 🥳</p>
        <p className="relative text-xs md:pt-8  text-left rounded-lg ">
          If we can't improve your listing, you get a high five and your money
          back. ❤️
        </p>
      </div> */}
    </div>
  );
}

export default App;
